import Navbar from './Navbar';

import React from 'react'

const Header = () => {
    return (
        <header>
           
                <Navbar />
          
        </header>
    )
}

export default Header
